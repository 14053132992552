import clsx from 'clsx';
import type { ButtonHTMLAttributes } from 'react';
import { forwardRef } from 'react';
import LoadingIndicator from '~/icons/LoadingIndicator';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  variant?: 'primary' | 'secondary' | 'danger' | 'success' | 'transparent';
  size?: 'sm' | 'md';
  icon?: boolean;
  loading?: boolean;
  className?: string;
}

export default forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      variant = 'primary',
      size = 'md',
      loading = false,
      icon = false,
      className,
      children,
      ...rest
    },
    ref,
  ) => (
    // eslint-disable-next-line react/button-has-type
    <button
      ref={ref}
      className={clsx(
        'outline-0 ring-offset-2 hover:shadow focus:ring-2 active:shadow-inner disabled:cursor-not-allowed disabled:bg-gray-500 disabled:opacity-25',
        !icon &&
          'relative flex items-center text-ellipsis whitespace-nowrap rounded-md px-4 font-medium',
        variant === 'primary' && 'bg-primary ring-primary text-white',
        variant === 'secondary' && 'bg-gray-200 text-gray-600 ring-gray-600',
        variant === 'danger' && 'bg-red-600 text-white ring-red-600',
        variant === 'success' &&
          'bg-green-500 text-white ring-green-500 hover:bg-green-600',
        size === 'sm' ? 'py-1' : 'py-2',
        icon && 'h-7 w-7 p-1',
        className,
      )}
      {...rest}
    >
      {loading && (
        <LoadingIndicator
          variant={variant}
          className="absolute top-2.5 left-3"
        />
      )}
      {children}
    </button>
  ),
);
