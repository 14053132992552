import clsx from 'clsx';
import type { FC } from 'react';

type LoadingndicatorProps = {
  variant?: 'primary' | 'secondary' | 'danger' | 'success' | 'transparent';
  className?: string;
};
const Loadingndicator: FC<LoadingndicatorProps> = ({
  variant = 'primary',
  className,
}) => (
  <svg
    className={clsx('-ml-1 mr-3 h-5 w-5 animate-spin', className)}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle
      cx="12"
      cy="12"
      r="10"
      stroke={variant === 'danger' ? '#ef4444' : 'white'}
      strokeWidth="4"
      opacity="0.35"
    />
    <path
      fill={variant === 'danger' ? '#ef4444' : 'white'}
      opacity="0.75"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    />
  </svg>
);

export default Loadingndicator;
